/*
  Use for redirect to another page when a target turbo-frame is on the page.
  broadcast_action_to("bullet_store_#{store_id}", action: :redirect,
                      target: "email_confirmation_#{id}", content: root_path)
*/

export default async stream => {
  const target = document.getElementById(stream.target)

  if (target) {
    const redirectUrl = stream.querySelector('template').innerHTML
    window.location.href = redirectUrl
  }
}
