/*
  Use for replace CSS class.
  <%= turbo_stream.action(:replace_class_all, 'input', [['border-red-500', 'border-gray-300']]) %>
  This will replace 'border-red-500' to 'border-gray-300' in 'input' targets.
*/

export default stream => {
  const contentHTML = stream.querySelector('template').innerHTML // [['border-gray-300', 'border-red-300']]

  if (contentHTML !== '') {
    const replacePairs = JSON.parse(contentHTML)
    const targets = document.querySelectorAll(stream.target)

    if (!targets) {
      console.error('Replace class all: targets not found', stream.target)
      return
    }

    targets.forEach(target => {
      replacePairs.forEach(pair => {
        const replace = pair[0]
        const to = pair[1]

        target.classList.remove(replace)
        target.classList.add(to)
      })
    })
  }
}
