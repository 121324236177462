/*
  Use for reload turbo-frame.
  'broadcast_action_to("bullet_store_#{store_id}", action: :reload_frame, target: 'video_edit_categories', content: '')'
  This will reload 'frame_id'.
*/

export default async stream => {
  const target = document.getElementById(stream.target)

  if (target) {
    if (target.dataset.src && target.dataset.src !== target.src) {
      target.src = target.dataset.src
    } else {
      target.reload()
    }
  }
}
