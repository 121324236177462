/*
  Use for replace CSS class.
  <%= turbo_stream.action(:replace_class, :title, [['border-gray-300', 'border-red-500']]) %>
  This will replace 'border-gray-300' to 'border-red-500' in 'title' target.
*/

export default stream => {
  const contentHTML = stream.querySelector('template').innerHTML // [['border-gray-300', 'border-red-300']]

  if (contentHTML !== '') {
    const replacePairs = JSON.parse(contentHTML)
    const target = document.getElementById(stream.target)

    if (!target) {
      console.error('Replace class: target not found', stream.target)
      return
    }

    replacePairs.forEach(pair => {
      const replace = pair[0]
      const to = pair[1]

      target.classList.remove(replace)
      target.classList.add(to)
    })
  }
}
