/*
  Custom turbo-stream actions here
*/

import replaceClass from './replace_class'
import replaceClassAll from './replace_class_all'
import reloadFrame from './reload_frame'
import redirect from './redirect'

const StreamActions = {
  replace_class: replaceClass,
  replace_class_all: replaceClassAll,
  reload_frame: reloadFrame,
  redirect
}

export { StreamActions }
